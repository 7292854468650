import React from "react"
import ReactPlayer from "react-player"
import Container from "layout/Container"

const Video = ({ videoSrcURL, videoTitle, thumbnail }) => {
  return (
    <Container isCentered desktop={8} fullhd={6}>
      <div className="has-border-primary-curve">
        <ReactPlayer
          url={videoSrcURL}
          width={"auto"}
          controls={true}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          height={"100%"}
          light={thumbnail}
        />
      </div>
    </Container>
  )
}

export default Video
