import React, { Fragment } from "react"

import Container from "layout/Container"
import Img from "gatsby-image"
import classNames from "classnames"
import styles from "./utils/elements.module.scss"

const HowItWorks = ({ howProgramWorks, useImages }) => {
  const data = useImages()
  return (
    <Container isCentered desktop={10} fullhd={8} backgroundColor="light" >
      <div className="py-2 py-0-mobile">
        <h2 className="mt-3 mb-4 has-text-centered has-text-primary">
          Schedule a consult in three steps
        </h2>
        <div className="columns is-centered mb-3 mx-1-mobile">
          {howProgramWorks.map((item, index) => (
            <Fragment key={index}>
              <div className="column is-relative mb-2">
                <div
                  className={classNames(
                    "columns is-mobile",
                    styles["steps_pseudo_element"]
                  )}
                  data-before-content={index + 1}
                >
                  <div className="column is-one-quarter is-one-fifth-mobile"></div>
                  <div
                    className={classNames("column p-0", styles["z_index_above"])}
                  >
                    <div className="columns is-mobile is-gapless mb-0">
                      <div className="column is-flex is-align-items-flex-end">
                        <h2 className="has-text-secondary mb-1">
                          {item?.title}
                        </h2>
                      </div>
                      <div className="how-it-works-wrapper column p-0 is-hidden-mobile">
                        <Img
                          loading="eager"
                          fixed={data[item?.imageName].childImageSharp.fixed}
                        />
                      </div>
                      <div className="how-it-works-wrapper column p-0 is-hidden-desktop is-hidden-tablet is-flex is-align-items-end">
                        <Img
                          loading="eager"
                          fixed={
                            data[item?.imageNameMobile].childImageSharp.fixed
                          }
                        />
                      </div>
                    </div>
                    <h3 className="has-text-weight-normal">
                      {item?.description}
                    </h3>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default HowItWorks
