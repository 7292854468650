import React from "react"
import hexagonBackground from "../../../static/images/icons/hexagon_background.svg"
import styles from "./utils/layout.module.scss"

const Hexagon = ({ children }) => {
  return (
    <div className="is-relative">
      {/* <img src={hexagonImage} alt="hexagon-background" className={styles["hexagon_background"]} /> */}
      <div className={styles["image_wrapper"]}>
        <img
          src={hexagonBackground}
          alt=""
          className={styles["hexagon_background"]}
        />
      </div>
      {children}
    </div>
  )
}

export default Hexagon
